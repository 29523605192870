$(document).ready(function () {
	//Declare normal variable javascript
	//Hide element when smaller than 1025

	if ($(window).width() < 1024.5) {
		$(".main-menu-nav").fadeIn(function () {
			$(".main-menu-nav").css({
				display: "flex"
			});
		});
	}
	if ($(window).width() > 1024.9) {
		linkHeight();
	}
	$(".search-toggle").on("click", function () {
		$(".searchbox").toggleClass("active");
	});

	//Library init
	$('[data-fancybox="modal"]').fancybox({
		// Options will go here
		modal: true,
		smallBtn: true,
		toolbar: true
	});
	//Declare function Javascript
	if ($(window).width() > 1024) {
		const $menu = $(".searchbox");
		$(document).mouseup(e => {
			if (
				!$menu.is(e.target) && // if the target of the click isn't the container...
				$menu.has(e.target).length === 0
			) {
				// ... nor a descendant of the container
				$menu.removeClass("active");
			}
		});
	}
	toggleMobileMenu();
	swiperInit();
	//FullPage
	$(".menu-icon").on("click", function () {
		$(this).toggleClass("open");
		$(".container").toggleClass("nav-open");
		$("nav ul li").toggleClass("animate");
	});
	AOS.init({
		// Global settings:
		disable: function () {
			var maxWidth = 1024.1;
			return window.innerWidth < maxWidth;
		},
		offset: 120, // offset (in px) from the original trigger point
		delay: 0, // values from 0 to 3000, with step 50ms
		duration: 700, // values from 0 to 3000, with step 50ms
		easing: 'ease', // default easing for AOS animations
		once: true, // whether animation should happen only once - while scrolling down
		mirror: false, // whether elements should animate out while scrolling past them
		anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

	});





});
//Check if windows size large then 1024 then these function will be execute
if ($(window).width() > 1024) {
	const $menu = $(".searchbox");
	$(document).mouseup(e => {
		if (
			!$menu.is(e.target) && // if the target of the click isn't the container...
			$menu.has(e.target).length === 0
		) {
			// ... nor a descendant of the container
			$menu.removeClass("active");
		}
	});
}

function linkMobile() {
	$('.home-link-award-5 .row').matchHeight({
		target: $('.swiper-home-field-wrapper')
	})
	$('.home-link-award-5').matchHeight({
		target: $('.swiper-home-field-wrapper')
	})
}

function linkHeight() {



	$('.home-link-award-5 .row').matchHeight({
		target: $('.home-field')
	})
}
if ($(window).width() < 1025) {
	mappingMenu();
	fixMenu();
	mappingPhone();
}
// Remove when click outside the circle



function toggleMobileMenu() {
	$(".mobile-toggle").on("click", function () {
		$(".mobile-wrapper").toggleClass("active");
	});
}

function swiperInit() {
	var homerSwiper = new Swiper(".swiper-home-field .swiper-container", {
		// Optional parameters
		spaceBetween: 0,
		speed: 1205,
		slidesPerView: 1,
		preloadImages: false,
		// Enable lazy loading
		lazy: true,
		loadPrevNext: true,
		loadOnTransitionStart: true,
		autoplay: {
			delay: 3000
		}
	});

	var swiperAchievenment = new Swiper(".home-field .swiper-container", {
		// Optional parameters
		speed: 1205,
		autoplay: {
			delay: 3000
		},
		preloadImages: false,
		// Enable lazy loading
		lazy: true,
		loadPrevNext: true,
		spaceBetween: 30,
		breakpointsInverse: true,
		loadOnTransitionStart: true,
		breakpoints: {
			320: {
				slidesPerView: 1
			},
			450: {
				slidesPerView: 2
			},

			768: {
				slidesPerView: 3
			},
			1024: {
				slidesPerView: 4
			},
			1200: {
				slidesPerView: 5
			}
		},

		spaceBetween: 30,
		navigation: {
			nextEl: ".nav-arrow-next.white-arrow",
			prevEl: ".nav-arrow-prev.white-arrow"
		}
	});
	var swiperNewsWrapper = new Swiper(".figure-news-wrapper .swiper-container", {
		// Optional parameters
		speed: 1205,
		autoplay: {
			delay: 3500
		},
		preloadImages: false,
		// Enable lazy loading
		lazy: true,
		loadPrevNext: true,
		slidesPerView: 1,
		spaceBetween: 30,

		navigation: {
			nextEl: ".nav-arrow-next-news",
			prevEl: ".nav-arrow-prev-news"
		}
	});
	var swiperNewsWrapper1 = new Swiper(".figure-gallery-wrapper .swiper-container", {
		// Optional parameters
		speed: 1205,

		preloadImages: false,
		// Enable lazy loading
		lazy: true,
		loadPrevNext: true,
		slidesPerView: 1,
		spaceBetween: 30,

		navigation: {
			nextEl: ".nav-arrow-next-gallery",
			prevEl: ".nav-arrow-prev-gallery"
		}
	});
	var swiperNewsWrapper1 = new Swiper(".swiper-home-partner-1 .swiper-container", {
		// Optional parameters
		speed: 1205,
		autoplay: {
			delay: 3000
		},
		preloadImages: false,
		// Enable lazy loading
		lazy: true,
		loadPrevNext: true,
		spaceBetween: 30,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 2
			},

			768: {
				slidesPerView: 3
			},
		},
		navigation: {
			nextEl: ".swiper-home-partner-1 .nav-arrow-next-news",
			prevEl: ".swiper-home-partner-1 .nav-arrow-prev-news"
		}
	});

	var swiperNewsWrapper2 = new Swiper(".swiper-home-partner-2 .swiper-container", {
		// Optional parameters
		speed: 1205,
		autoplay: {
			delay: 3000
		},
		preloadImages: false,
		// Enable lazy loading
		lazy: true,
		loadPrevNext: true,
		spaceBetween: 30,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 2
			},

			768: {
				slidesPerView: 3
			},
		},
		navigation: {
			nextEl: ".swiper-home-partner-2 .nav-arrow-next-news",
			prevEl: ".swiper-home-partner-2 .nav-arrow-prev-news"
		}
	});

	console.log(swiperNewsWrapper);





}


function mappingMenu() {
	return new MappingListener({
		selector: ".main-menu-nav",
		mobileWrapper: ".mobile-wrapper",
		mobileMethod: "appendTo",
		desktopWrapper: ".header-wrapper-center",
		desktopMethod: "appendTo",
		breakpoint: 1025
	}).watch();
}

function mappingPhone() {
	return new MappingListener({
		selector: ".header-phone",
		mobileWrapper: ".mobile-wrapper",
		mobileMethod: "appendTo",
		desktopWrapper: ".search-wrapper",
		desktopMethod: "insertAfter",
		breakpoint: 1025
	}).watch();
}

function fixedLink() {
	if (
		document.body.scrollTop > 300 ||
		document.documentElement.scrollTop > 300
	) {
		$(".home-link-award-5 .row").addClass("minimize");
	} else {
		$(".home-link-award-5 .row").removeClass("minimize");
	}
}
window.onscroll = function () {
	if ($(window).width() > 1024.5) {
		fixedLink();
	}

};

function fixMenu() {
	return new MappingListener({
		selector: ".fixed-menu-wrapper",
		mobileWrapper: ".mobile-wrapper",
		mobileMethod: "appendTo",
		desktopWrapper: "main",
		desktopMethod: "insertAfter",
		breakpoint: 4000
	}).watch();
}


$(document).ready(function () {
	jQuery(function ($) {
		"use strict";

		var counterUp = window.counterUp["default"]; // import counterUp from "counterup2"

		var $counters = $(".counter");

		/* Start counting, do this on DOM ready or with Waypoints. */
		$counters.each(function (ignore, counter) {
			var waypoint = new Waypoint({
				element: $(this),
				handler: function () {
					counterUp(counter, {
						duration: 500,
						delay: 2
					});
					this.destroy();
				},
				offset: "bottom-in-view"
			});
		});
	});
});
